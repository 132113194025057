<template>
  <div class="tryOut">
    <div class="tryOut-tap"></div>
    <div class="tryOut-main">
      <div class="tryOut-main-w">
        <img
          class="tryOut-main-w-logo"
          src="../assets/tryOut/tryOutBgLogo.png"
          alt=""
          v-if="state == 1"
        />
        <img
          class="tryOut-main-w-logo"
          src="../assets/tryOut/tryOutBgLogo2.png"
          alt=""
          v-else
        />
        <div class="tryOut-main-w-form" v-loading="loadingCode">
          <div class="centered">免费申请试用</div>
          <div class="tryOut-main-w-form-text centered">
            请填写基本信息，我们将尽快与您联系
          </div>
          <!--  表单 -->
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="120px"
            class="demo-ruleForm"
          >
            <!-- 姓名 -->
            <el-form-item label="姓 名:" prop="name">
              <el-input
                prefix-icon="iconfont icon-icon_username"
                v-model="ruleForm.name"
              ></el-input>
            </el-form-item>
            <!-- 手机号 -->
            <el-form-item label="手机号:" prop="phone">
              <el-input
                prefix-icon="iconfont icon-shoujihao"
                v-model="ruleForm.phone"
              ></el-input>
            </el-form-item>
            <!-- 验证码 -->
            <div class="verification">
              <el-form-item label="验证码:" prop="code">
                <el-input
                  prefix-icon="iconfont icon-yanzhengma"
                  v-model="ruleForm.code"
                ></el-input>
              </el-form-item>
              <div class="verification-btn" @click="getCode">{{ codeStr }}</div>
            </div>
            <!-- 公司 -->
            <el-form-item label="公 司:" prop="company">
              <el-input
                prefix-icon="iconfont icon-jurassic_company"
                v-model="ruleForm.company"
              ></el-input>
            </el-form-item>
            <!-- 城市 -->
            <el-form-item label="城 市:" prop="city">
              <el-input
                prefix-icon="iconfont icon-dizhi"
                v-model="ruleForm.city"
              ></el-input>
            </el-form-item>
          </el-form>
          <!-- 提交按钮 -->
          <div class="submit" @click="submit('ruleForm')">同意协议并提交</div>
          <!-- 用户协议 -->
          <div style="margin: 0 auto; width: 400px">
            <el-checkbox v-model="checked"
              >我已阅读并同意
              <span @click.stop="agreement = 1" style="color: #5498fc"
                >《薪津隐私声明》</span
              >
              及
              <span @click.stop="agreement = 2" style="color: #5498fc"
                >《用户协议》</span
              ></el-checkbox
            >
          </div>
        </div>
      </div>
      <div class="tryOut-main-bottom">
        <div class="tryOut-main-bottom-badge">
          <img src="../assets/badge.png" alt="" />
          <div>鄂公网安备 42011102004646号</div>
        </div>
        <div>Copyright 薪津科技 All Rights Reserved</div>
      </div>
    </div>

    <div class="tryOut-agreement" v-if="agreement">
      <!--隐私政策  -->
      <div class="tryOut-agreement-main w" v-if="agreement == 1">
        <div class="tryOut-agreement-return" @click="agreement = ''">返回</div>
        <h1>隐私声明</h1>
        <p>
          本隐私保护声明适用于使用“薪津科技”网站（以下简称本网站）提供的各种服务的用户。我们尊重、保护用户的个人隐私，并郑重承诺，除非有法律或程序要求，否则未经用户授权我们不会公布与用户个人有关的资料。我们将定期检查隐私保护措施，切实保证用户个人资料隐私保护。
        </p>
        <p>未经授权禁止转载、摘编、复制或建立镜像，违者将追究其法律责任。</p>
        <p>Email：liubei@027xjkj.com</p>
        <p>
          用户使用本网站的信箱、访谈、网上申报等栏目时，网站会根据具体实际情况需要用户提供必要的个人资料，这些资料均属于隐私保护范围，我们将对这些资料内容进行隐私保护。
        </p>
        <p>
          在以下几个例外情形下，将无法保证前述的承诺，而披露用户的相关信息。这些情形包括但不限于：
        </p>
        <p>
          （1）当用户在本网站的行为违反了《计算机信息网络国际联网安全保护管理办法》、《互联网信息服务管理办法》，或可能损害或妨碍其他网友的权益或导致他人遭受损害，披露用户的个人资料是为了辨识、联络或采取法律行动所必要的行动时。
        </p>
        <p>（2）法律法规所规定的必须披露或公开的个人信息。</p>
        <p>
          （3）当司法机关或其它授权机关依法执行公务，要求网站公开特定个人资料时。
          本网站具有全面的技术保护措施和安全维护机制来保证用户信息的内容，但由于不可抗力或者因计算机病毒感染、黑客攻击等特殊外力侵扰，导致用户信息破坏、泄密并受到损失的，本网站不承担任何法律责任。
        </p>
        <p class="tryOut-agreement-main-date">发布时间：2022年12月19日</p>
        <p class="tryOut-agreement-main-date">生效时间：2022年12月19日</p>
      </div>
      <!-- 用户协议 -->
      <div class="tryOut-agreement-main w" v-if="agreement == 2">
        <div class="tryOut-agreement-return" @click="agreement = ''">返回</div>
        <h1>用户协议</h1>
        <h3>一、用户协议的成立</h3>
        <p>
          本网站用户协议（“用户协议”）是用户与网站（简称“网站”，网址：027xjkj.com）运营者（以下简称为“甲方”
          ，与用户合称“双方”）之间就网站向用户提供服务等相关事宜所订立的协议，用户应当仔细阅读本用户协议。一旦用户点击确认本协议，并在网站注册成功，本用户协议即构成对双方有约束力的法律文件。
        </p>
        <h3>二、用户协议的修改</h3>
        <p>
          甲方保留依其自主判断在将来的任何时间变更、修改、增加或删除本用户协议条款的权利。所有对协议条款的修改均构成本用户协议的一部分。甲方有权随时对本用户协议项下条款进行修改，一旦发生条款变动，甲方将[通过网站公布等方式向乙方]提示修改的内容。用户继续使用甲方通过网站提供的服务视为其同意甲方对用户协议的修改。
        </p>
        <h3>三、服务内容</h3>
        <p>
          甲方运用其操作系统通过国际互联网和移动互联网向用户提供包括各种[人力资源相关的信息工具、个性化内容]等服务（以下简称“服务”）。除非另有明确规定，增强或强化目前服务的任何新功能均适用本用户协议。
        </p>
        <h3>四、用户保证</h3>
        <p>
          用户提供的信息是准确、完整、真实、合法的，不存在虚假、欺诈、骚扰性的、中伤他人的、伤害性的、诽谤、侮辱、恐吓、庸俗淫秽等违反法律、公序良俗或社会伦理道德的信息或内容，也不存在危害网络或计算机系统的安全性、完整性、稳定性、连续性的病毒、木马程序等电脑软件、文档或其他数据资料。
        </p>
        <p>
          用户对用户提供的信息拥有完整的、独立的权利，或者已经得到相关权利人的合法授权，用户保证甲方使用用户提供的信息不会侵犯任何第三方的合法权利，亦不会违反用户与任何第三方的约定。
        </p>
        <p>
          用户进行注册、使用甲方通过网站提供的信息或服务系出于个人需要，用户不得将其通过网站获得的信息或服务进行复制、出售、交易、公布、用于商业活动、或用于其他营利目的，用户仅可为其自身需要之目的使用该等信息或服务。
        </p>
        <p>
          如果用户违反上述第四条第1款至第3款的规定，或甲方有正当理由相信用户存在违反上述规定的情形，则甲方有权利通过技术手段或采取其他适当措施中断或终止向该用户继续提供网站服务，包括但不限于屏蔽、封锁该用户IP地址、注销用户的账户等。用户如有异议，可向甲方提出，并提供相应的证明材料。对于该用户因此而遭受的任何损失，甲方不承担任何责任。
        </p>
        <p>
          对于用户提供信息中所涉及的用户个人信息，甲方将按照中华人民共和国相关法律规定予以保护。
        </p>
        <p>
          用户同意授予甲方在全球范围内受限于法律法规的范围之内免费的、永久性的、不可撤销的使用、复制、修改、传播和展示用户提供信息的权利和许可。
        </p>
        <h3>五、网站使用</h3>
        <p>
          用户在使用本网站过程中，应当遵守所有适用的法律和法规以及本用户协议。
        </p>
        <p>用户不得从事以下行为：</p>
        <p>
          上载、张贴、发送或传送任何非法、有害、淫秽、粗俗、猥亵的，胁迫、骚扰、中伤他人的，诽谤、侵害他人隐私或诋毁他人名誉或商誉的，种族歧视或其他不适当的信息或电子邮件，包括但不限于资讯、资料、文字、软件、音乐、照片、图形、信息或其他资料。
        </p>
        <p>以任何方式危害未成年人。</p>
        <p>
          冒充任何人或机构，或以虚伪不实的方式谎称或使人误认为其与任何人或任何机构有关。
        </p>
        <p>伪造标题或以其他方式操控识别资料，使人误认为该内容为甲方所传送。</p>
        <p>
          上载、张贴、发送电子邮件或以其他方式传送其无权传送的内容（例如内部资料、机密资料）。
        </p>
        <p>
          上载、张贴、发送电子邮件或以其他方式传送侵犯任何人的专利、商标、著作权、商业秘密或其他合法权利之内容。
        </p>
        <p>
          在甲方网站专供张贴广告的区域之外，上载、张贴、发送电子邮件或以其他方式传送广告函件、促销资料、“垃圾邮件”等。
        </p>
        <p>
          上载、张贴、发送电子邮件或以其他方式传送有关干扰、破坏或限制任何计算机软件、硬件或通讯设备功能的软件病毒或其他计算机代码、档案和程序之资料。
        </p>
        <p>
          干扰或破坏本服务或与本服务相连的服务器和网络，或不遵守本用户协议之规定。
        </p>
        <p>
          故意或非故意违反任何相关的中国法律、法规、规章、条例等其他具有法律效力的规范。
        </p>
        <p>其它被甲方视为不适当的行为。</p>
        <p>
          用户在使用本网站时违反任何上述规定，甲方可自行判断要求用户改正或采取一切必要的措施（包括但不限于删除用户上传的内容、暂停或终止用户使用本网站的权利）以减轻用户不当行为造成的影响。
        </p>
        <h3>六、知识产权</h3>
        <p>
          用户对甲方或甲方关联企业拥有的商标、商号、标识、图案、本网站的名称及域名（以下简称“甲方标识”）不拥有任何权利。本用户协议亦不授予用户有关甲方标识的任何权利或权益。在未经甲方事先书面同意的情况下，用户不得在任何宣传或促销材料、出版物中或媒体上使用甲方标识。
        </p>
        <p>
          本网站中包含的任何信息、文本、图形等网站内容均受著作权法、商标法和/或其他知识产权法等相关法律的保护，其相关权利归甲方或甲方关联方或该内容提供者所有。未经甲方或有关权利人事先同意，用户不得复制、分发、传播、修改、编辑，或在任何媒体直接或间接发布、播放该等内容，或者将该等内容用于任何商业目的。
        </p>
        <p>
          在甲方或有关权利人事先同意的前提下，并且在遵循甲方或有关权利人指示的基础上，用户可以对网站内容（包括但不仅限于所有有关电子文档、广告宣言、本用户协议）进行复制或分发，但用户必须确保复制和分发的内容是完整和真实的。
        </p>
        <h3>七、用户帐号、密码和安全</h3>
        <p>
          用户一旦注册成功，便成为甲方的合法用户。用户有义务保证密码和帐号的安全。用户对利用该密码和帐号所进行的一切活动负全部责任。用户因未能妥善保管密码和账号而产生的任何损失或损害，甲方不承担任何责任。
        </p>
        <h3>八、责任限制</h3>
        <p>
          基于互联网的特性及技术现状，甲方虽将尽其努力，但无法保证向用户提供的服务是连续的、即时的、准确的，无法保证缺陷一定会被及时纠正，也无法保证所提供的服务或服务器不受病毒或黑客等有害成分的侵袭。服务和网站内容依现状提供，甲方不作任何形式的有关服务或网站内容的准确性、完整性、令人满意的品质、不侵权或适合某一特殊目的的保证。
        </p>
        <p>
          甲方保留随时修改、增减、取消、暂停、中断或终止提供（全部或部分）本网站的服务的权利，甲方就此将给予用户适当通知，但甲方在行使前述各项权利时无需取得用户同意，也不对用户承担任何责任。
        </p>
        <p>
          本网站服务器如因系统维护或升级而需暂停服务时，将事先公告。如因系统维护、升级而需暂停服务或因服务器故障、硬件故障、其他不可抗力因素而导致停止服务，于暂停服务期间造成的一切不便和资料、数据等遗失，本网站将尽力挽救恢复；如确实无法恢复，本网站不承担任何责任。
        </p>
        <p>
          对于用户提供或发布的信息，包括但不限于因用户在没有获得授权的情形下使用权利人的信息，或因用户提供信息包含错误、不准确、病毒、诽谤、中伤、侵权等法律所禁止的内容或其他不适当内容而产生的任何责任，应由相关用户承担。用户应对其提供信息自行保留备份，甲方亦不对用户提供信息在任何情形下的丢失、删除、移除或传输失败承担任何责任。
        </p>
        <p>
          对于用户通过本网站外部链接与第三方发生的任何法律行为，包括但不限于与第三方的交易，由用户与该第三方自行承担全部责任，甲方不承担任何责任。对于存在或源于此外部链接网站或资源的任何内容、广告、产品或其它资料，甲方不予任何保证，亦不承担任何责任。
        </p>
        <p>
          用户特此承认并接受：甲方为用户便利而[无偿]向用户提供网站信息服务，对于用户因使用本网站而遭受的损失，包括但不限于由于网站内容或服务的延误、不准确、错误和遗漏而产生的任何损害、责任、请求、损失或费用，甲方及甲方关联方不承担任何责任。
        </p>
        <h3>九、赔偿</h3>
        <p>
          因用户违反本网站用户协议的规定而产生的针对甲方或甲方关联方或由甲方或甲方关联方承担的任何类型的索赔、要求、费用、支出、损失、诉讼、政府调查和/或处罚、损害赔偿和责任，用户应向甲方或甲方关联方予以赔偿（包括但不限于律师和顾问费），且用户应为甲方抗辩，使甲方免受损害。
        </p>
        <h3>十、通知</h3>
        <p>
          甲方向用户发出的通知，可以采用电子邮件、页面公告、常规信件或甲方认为适合的形式。
        </p>
        <h3>十一、法律的适用和管辖</h3>
        <p>
          本用户协议的解释和执行均适用中华人民共和国法律。本用户协议及本网站内公布的任何附加条款，构成甲方与用户之间关于使用本网站的完整协议。如果该协议的内容与适用的强制性法律规定不一致，则以该强制性法律规定为准。因该协议的履行产生的任何纠纷，首先由双方协商解决；协商不成的，任何一方均可向[甲方所在地人民法院]提起诉讼。
        </p>
        <p class="tryOut-agreement-main-date">发布时间：2022年12月19日</p>
        <p class="tryOut-agreement-main-date">生效时间：2022年12月19日</p>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL } from '@/utils/request'
export default {
  name: 'OfficialWebsiteTryOut',
  data() {
    return {
      //  1 人力资源管理系统  2 灵活用工平台
      state: '',
      //  用户协议勾选框
      checked: false,
      // 频繁点击
      isLock: false,
      //  验证码的遮罩层
      loadingCode: false,
      codeStr: '获取验证码',
      times: 60000,
      //  1 隐私政策  2 用户协议
      agreement: '',
      ruleForm: {
        name: '',
        phone: '',
        company: '',
        city: '',
        code: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' },
          {
            type: 'string',
            required: true,
            message: '请输入正确的姓名',
            pattern: /^([\u4e00-\u9fa5]{2,6})$/
          }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            type: 'string',
            required: true,
            message: '请输入合法手机号',
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
          }
        ],
        company: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        city: [
          { required: true, message: '请输入城市', trigger: 'blur' },
          {
            type: 'string',
            required: true,
            message: '请输入汉字',
            pattern: /^[\u4E00-\u9FA5]+$/
          }
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          {
            type: 'string',
            required: true,
            message: '请输入数字',
            pattern: /^[0-9]*$/
          }
        ]
      }
    }
  },

  mounted() {},

  created() {
    this.state = this.$route.query.state
  },

  methods: {
    //  获取验证码
    async getCode() {
      if (!this.ruleForm.phone) {
        this.$message.warning('请先输入手机号')
        return
      }
      if (this.isLock) {
        this.$message.warning('请勿多次重复点击')
        return
      }
      if (this.times < 60000 && this.times > 0) {
        return
      }
      this.loadingCode = true
      this.isLock = true
      const res = await this.$http.get(
        '/wxapplet/experience/getCode?phone=' + this.ruleForm.phone
      )
      this.loadingCode = false
      if (res.data.code === 200) {
        this.$message.success(res.data.msg)
      } else {
        this.$message.error(res.data.msg)
      }
      const timer = setInterval(() => {
        if (this.times <= 0) {
          this.codeStr = '获取验证码'
          clearInterval(timer)
          this.times = 60000
          this.isLock = false
        } else {
          this.times -= 1000
          this.codeStr = this.times / 1000 + 's后获取'
        }
      }, 1000)
    },

    // 提交表单
    async submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (!this.checked) {
            this.$message.warning('请先勾选协议')
            return
          }
          this.$http({
            // 默认请求方式为get
            method: 'post',
            url: baseURL + 'wxapplet/experience/insert',
            // 传递参数
            data: this.ruleForm,
            responseType: 'json',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          })
            .then((res) => {
              console.log('<<<<<', res)
              if (res.data.code === 200) {
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
            .catch((error) => {
              console.log(error)
              // 请求失败，
            })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.centered {
  text-align: center;
}

.tryOut {
  height: 100%;
}

.tryOut-tap {
  height: 77px;
  margin-bottom: 2px;
  box-shadow: 0px 2px 2px 0px rgba(176, 176, 176, 0.2);
}

.tryOut-agreement {
  height: 100%;
  position: fixed;
  width: 100%;
  top: 0;
  overflow: auto;
  background-color: #fff;
  z-index: 9999;

  h1 {
    text-align: center;
  }

  .w {
    position: relative;
    width: 1200px;
    margin: 0 auto;

    .tryOut-agreement-return {
      position: absolute;
      left: 0;
      top: 13px;
      color: #6f95fd;
      cursor: default;
    }
  }

  .tryOut-agreement-main-date {
    text-align: right;
  }
}
.tryOut-main {
  background: url('../assets/tryOut/tryOutBg.png') center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  position: fixed;
  width: 100%;

  .tryOut-main-w {
    display: flex;
    width: 1200px;
    margin: 0 auto;
    padding-top: 109px;
    justify-content: space-between;

    .tryOut-main-w-logo {
      width: 690px;
      height: 550px;
      margin-top: 88px;
    }

    .tryOut-main-w-form {
      width: 480px;
      height: 660px;
      font-weight: 700;
      font-size: 24px;
      color: #333333;
      padding-top: 60px;
      background-color: #fff;

      .tryOut-main-w-form-text {
        font-size: 16px;
        color: #9e9e9e;
        font-weight: 400;
        margin: 12px 0 40px;
      }

      /deep/ .el-input {
        width: 320px;
      }

      /deep/ .el-input__prefix {
        margin-left: 5px;
      }

      /deep/ .iconfont {
        color: #888888;
      }

      /deep/ .el-form-item__label {
        color: #888;
        font-weight: 400;
      }

      .verification {
        position: relative;

        .verification-btn {
          display: flex;
          cursor: default;
          position: absolute;
          top: 0;
          right: 40px;
          width: 120px;
          height: 40px;
          font-weight: 400;
          color: #fff;
          font-size: 16px;
          align-items: center;
          justify-content: center;
          background: #5498fc;
        }
      }

      .submit {
        width: 400px;
        height: 50px;
        user-select: none;
        text-align: center;
        line-height: 50px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        margin: 61px auto 0px;
        background: #5498fc;
      }
    }
  }

  .tryOut-main-bottom {
    margin-top: 80px;
    font-size: 14px;
    color: #939393;
    text-align: center;
    .tryOut-main-bottom-badge {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
