<template>
  <div class="main">
    <div class="navigationBar" v-if="navState">
      <div class="w navigationBar-main">
        <div style="flex: 2">
          <img
            v-if="index == 1"
            src="../assets/logo.png"
            alt=""
            class="navigationBar-main-logo"
          />
          <img
            v-else
            src="../assets/logo2.png"
            alt=""
            class="navigationBar-main-logo"
          />
        </div>
        <div
          style="
            flex: 3;
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <div class="navigationBar-main-btn">
            <div
              class="navigationBar-main-btn-item"
              :class="{ active: index == 1 }"
              @click="cut(1)"
            >
              <span :class="{ activeTextAll: index != 1 }">首页</span>
            </div>
            <div
              class="navigationBar-main-btn-item interval"
              :class="{ active2: index == 2 }"
              @click="cut(2)"
            >
              <span
                :class="{ activeTextAll: index != 1, activeText: index == 2 }"
                >人力资源管理系统</span
              >
            </div>
            <div
              class="navigationBar-main-btn-item"
              :class="{ active2: index == 3 }"
              @click="cut(3)"
            >
              <span
                :class="{ activeTextAll: index != 1, activeText: index == 3 }"
                >灵活用工平台</span
              >
            </div>
          </div>
          <div
            class="login"
            :class="{ login2: index != 1 }"
            @click="dialogVisible = true"
          >
            登录
          </div>
        </div>
      </div>
    </div>

    <!-- 弹出框 -->
    <el-dialog :visible.sync="dialogVisible" width="950px" :show-close="false">
      <div class="dialog-main">
        <div class="dialog-main-title">
          <div>开启智能招聘新体验</div>
          <img src="../assets/x.png" alt="" @click="dialogVisible = false" />
        </div>
        <div class="dialog-main-bottom">
          <!-- 人力资源管理SaaS系统 -->
          <div class="dialog-main-bottom-item">
            <img src="../assets/dialogLogo1.png" alt="" />
            <div>人力资源管理SaaS系统</div>
            <div class="dialog-main-bottom-item-text">
              助力人力资源产业实现数字化型转变
            </div>
            <div class="dialog-main-bottom-item-btn" @click="href(1)">
              立即进入
            </div>
          </div>
          <!-- 灵活用工SaaS系统 -->
          <div class="dialog-main-bottom-item">
            <img src="../assets/dialogLogo2.png" alt="" />
            <div>灵活用工SaaS系统</div>
            <div class="dialog-main-bottom-item-text">
              企业灵活用工综合解决方案供应平台
            </div>
            <div class="dialog-main-bottom-item-btn" @click="href(0)">
              立即进入
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'DemoVueNavigationBar',
  computed: {
    ...mapState(['index'])
  },

  data() {
    return {
      // index: 1
      navState: true,
      dialogVisible: false,
      _beforeUnloadtime: '',
      _gaptime: ''
    }
  },

  mounted() {
    // 页面监听页面刷新和关闭
    window.addEventListener('beforeunload', (e) => this.beforeunloadFn(e))
    window.addEventListener('unload', (e) => this.unloadHandler(e))

    if (localStorage.getItem('index')) {
      this.$nextTick(() => {
        this.editIndex(localStorage.getItem('index'))
      })
    }
  },

  destroyed() {
    // 页面销毁前关闭监听
    window.removeEventListener('beforeunload', (e) => this.beforeunloadFn(e))
    window.removeEventListener('unload', (e) => this.unloadHandler(e))
  },

  watch: {
    $route: {
      handler: async function (val, oldVal) {
        if (val.name === '404') {
          this.navState = false
        } else {
          this.navState = true
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true
    }
  },

  created() {
    // localStorage.setItem('index', 1)
    // this.editIndex(1)
  },

  methods: {
    ...mapMutations(['editIndex']),

    beforeunloadFn(e) {
      this._beforeUnloadtime = new Date().getTime()
    },
    unloadHandler(e) {
      this._gaptime = new Date().getTime() - this._beforeUnloadtime
      // 判断是窗口关闭还是刷新
      if (this._gaptime <= 5) {
        // 浏览器关闭
        // 自己的操作行为
        // console.log('关闭浏览器')
        this.reset()
      } else {
        // console.log('刷新浏览器')
      }
    },

    //  路由跳转
    cut(num) {
      // this.index = num
      localStorage.setItem('index', num)
      // this.index = localStorage.getItem('index')
      this.editIndex(localStorage.getItem('index'))
      // console.log(this.index)
      if (num === 1) {
        this.$router.push({ path: '/home' })
      } else if (num === 2) {
        this.$router.push({ path: '/system' })
      } else {
        this.$router.push({ path: '/platform' })
      }
    },

    // 重置样式
    reset() {
      // console.log(1111)
      localStorage.setItem('index', 1)
      this.editIndex(1)
    },

    // 刷新页面
    refresh() {
      // console.log(222)
      const num = localStorage.getItem('index')
      this.editIndex(localStorage.getItem('index'))
    },

    //  页面跳转
    href(num) {
      if (num) {
        window.location.href = 'https://api.027xjkj.com/login'
      } else {
        window.location.href = 'https://salary.027xjkj.com'
      }
    },

    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    }
  }
}
</script>

<style lang="less" scoped>
.w {
  min-width: 1200px;
  margin: 0 auto;
}

.interval {
  // margin: 0 23px;
}

.router-link-active {
  // background-color: orange;
  color: #fff;
  padding: 0 14px;
  height: 77px;
  text-decoration: none;
}

.active {
  border-bottom: 2px solid #fff;
}

.active2 {
  color: #438efc;
  // color: #438efc !important;
  border-bottom: 2px solid #438efc;
}

.activeTextAll {
  color: #333333;
}

.activeText {
  color: #438efc;
}

.main {
  position: relative;
}

.navigationBar {
  position: absolute;
  left: 50%;
  top: 0;
  height: 77px;
  z-index: 100;
  transform: translateX(-50%);
  // background-color: red;
  .navigationBar-main {
    height: 100%;
    display: flex;
    align-items: center;
    // background-color: pink;
    .navigationBar-main-logo {
      width: 110px;
      height: 40px;
    }

    .navigationBar-main-btn {
      display: flex;
      width: 378px;
      color: #fff;
      cursor: default;
      align-items: center;
      justify-content: space-between;

      .navigationBar-main-btn-item {
        height: 77px;
        display: flex;
        padding: 0 14px;
        align-items: center;

        a {
          display: flex;
          padding: 0 14px;
          height: 77px;
          color: #fff;
          // color: #000;
          align-items: center;
          text-decoration: none;
        }
      }
    }

    .navigationBar-main-btn2 {
      display: flex;
      width: 378px;
      color: #fff;
      align-items: center;
      justify-content: space-between;

      .navigationBar-main-btn2-item {
        height: 77px;
        display: flex;
        align-items: center;

        a {
          display: flex;
          padding: 0 14px;
          height: 77px;
          // color: #333333 !important;
          color: #000;
          align-items: center;
          text-decoration: none;
        }
      }
    }
  }

  .login {
    width: 90px;
    height: 32px;
    cursor: default;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
    border: 1px solid #ffffff;
    border-radius: 16px;
  }

  .login2 {
    cursor: default;
    color: #438efc;
    border: 1px solid #438efc;
  }
}

.dialog-main-title {
  display: flex;
  align-items: center;
  margin: 0px 40px 62px 49px;
  font-size: 28px;
  font-weight: 700;
  color: #333333;
  justify-content: space-between;

  img {
    width: 22px;
    height: 22px;
  }
}

.dialog-main-bottom {
  display: flex;
  width: 711px;
  height: 390px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;

  .dialog-main-bottom-item {
    display: flex;
    width: 310px;
    height: 390px;
    font-weight: 700;
    font-size: 20px;
    color: #333333;
    padding: 40px 0 30px;
    align-items: center;
    flex-direction: column;
    background: #ffffff;
    border: 2px solid #f2f2f2;
    border-radius: 4px;

    img {
      width: 250px;
      height: 150px;
      margin-bottom: 40px;
    }

    .dialog-main-bottom-item-text {
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      margin: 8px 0 30px;
    }

    .dialog-main-bottom-item-btn {
      width: 150px;
      height: 44px;
      line-height: 44px;
      cursor: default;
      color: #ffffff;
      font-size: 18px;
      text-align: center;
      background: #438efc;
      border-radius: 2px;
    }
  }
}
</style>
