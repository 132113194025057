<template>
  <div id="app">
    <NavigationBar />
    <router-view />
  </div>
</template>

<script>
import NavigationBar from '@/components/NavigationBar.vue'
export default {
  components: {
    NavigationBar
  }
}
</script>

<style lang="less">
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
}

.w {
  width: 1200px;
  margin: 0 auto;
}

* {
  /* 清除默认内外边距 */
  margin: 0;
  padding: 0;
  /* 內减模式 */
  box-sizing: border-box;
}
/* 去掉列表默认样式 */
li {
  list-style: none;
}
/* 去掉链接默认下划线 */
a {
  text-decoration: none;
}
/*去掉button 默认自带的边框*/
button {
  border: none;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 5px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #c8c9cb;
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, 0.4);
}
</style>
