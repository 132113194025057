import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import System from '../views/system.vue'
import Platform from '../views/platform.vue'
import TryOut from '../views/tryOut.vue'
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    // 重定向
    redirect: '/home'
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home.vue')
    // component: Home
  },
  {
    path: '/system',
    name: 'system',
    component: () => import('@/views/system.vue')
    // component: System
  },
  {
    path: '/platform',
    name: 'platform',
    component: () => import('@/views/platform.vue')
    // component: Platform
  },
  {
    path: '/tryOut',
    name: 'tryOut',
    component: () => import('@/views/tryOut.vue')
    // component: TryOut
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404.vue')
  }, {
    path: '*', // 此处需特别注意置于最底部
    redirect: '/404'
  }
]

const router = new VueRouter({
  routes
})

export default router
