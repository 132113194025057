import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    index: 1
  },
  getters: {
  },
  mutations: {
    editIndex (state, num) {
      state.index = num
    }
  },
  actions: {
  },
  modules: {
  }
})
