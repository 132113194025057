<template>
  <div class="system">
    <div class="top">
      <div class="top-main w">
        <div class="top-main-left">
          <div>人力资源管理系统</div>
          <div class="top-main-left-text">
            互联网+新业态+新服务 一站式全流程服务平台
          </div>
          <div class="top-main-left-btn" @click="tryOut">免费试用</div>
        </div>
        <div class="top-main-right">
          <img src="../assets/system/systemBanner.png" alt="" />
        </div>
      </div>
    </div>

    <!-- 四大难题 -->
    <div class="puzzle">
      <div class="puzzle-main w">
        <div>劳务公司四大难题</div>
        <div class="puzzle-main-text">
          调研数百家人力企业负责人发现以下行业痛点
        </div>
        <div class="puzzle-main-card">
          <div class="puzzle-main-card-item">
            <img src="../assets/system/puzzle1.png" alt="" />
            <div class="puzzle-main-card-item-text">招聘难</div>
          </div>
          <div class="puzzle-main-card-item">
            <img src="../assets/system/puzzle1.png" alt="" />
            <div class="puzzle-main-card-item-text">管理难</div>
          </div>
          <div class="puzzle-main-card-item">
            <img src="../assets/system/puzzle1.png" alt="" />
            <div class="puzzle-main-card-item-text">发薪难</div>
          </div>
          <div class="puzzle-main-card-item">
            <img src="../assets/system/puzzle1.png" alt="" />
            <div class="puzzle-main-card-item-text">投保难</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 传统方式 -->
    <div class="tradition">
      <div class="tradition-main w">
        <div class="centered">传统方式</div>
        <div class="tradition-main-text centered">
          调研数百家人力企业负责人发现以下行业痛点
        </div>
        <div class="tradition-main-card">
          <div class="tradition-main-card-item">
            <img src="../assets/system/tradition1.png" alt="" />
            <div class="tradition-main-card-item-text">
              劳务公司主流采取网招和渠道送人模式，没有稳定的“造血能力”
            </div>
          </div>
          <div class="tradition-main-card-item">
            <img src="../assets/system/tradition2.png" alt="" />
            <div class="tradition-main-card-item-text">
              人工录入核对外包、派遣员工信息造成信息出错，效率太低
            </div>
          </div>
          <div class="tradition-main-card-item">
            <img src="../assets/system/tradition3.png" alt="" />
            <div class="tradition-main-card-item-text">
              临时性外派人员身份证号、银行卡信息收集繁琐，发薪逐笔核对
            </div>
          </div>
          <div class="tradition-main-card-item">
            <img src="../assets/system/tradition4.png" alt="" />
            <div class="tradition-main-card-item-text">
              员工入职投保“空窗期”劳务公司风险增大
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 解决方案 -->
    <div class="scheme">
      <div class="scheme-main w">
        <div class="centered">解决方案</div>
        <div class="scheme-main-text centered">搭建私域流量池，流程数字化</div>
        <div class="scheme-main-card">
          <div class="scheme-main-card-item">
            <img src="../assets/system/scheme1.png" alt="" />
            <div>量体打造私域化专属小程序，建立数字化简历库</div>
          </div>
          <div class="scheme-main-card-item">
            <img src="../assets/system/scheme2.png" alt="" />
            <div>单张、多张身份证照一键识别。PC端后台实时展示数据</div>
          </div>
          <div class="scheme-main-card-item">
            <img src="../assets/system/scheme3.png" alt="" />
            <div>员工微信二维码扫码实名认证，薪资一键下发</div>
          </div>
          <div class="scheme-main-card-item">
            <img src="../assets/system/scheme4.png" alt="" />
            <div>保险公司系统对接，投保直达，风险更低</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 人力资源管理SaaS系统 -->
    <div class="SaaS">
      <div class="SaaS-main w">
        <div class="centered" style="margin-top: 98px">
          人力资源管理SaaS系统
        </div>
        <div class="SaaS-main-text centered">
          助力人力资源产业实现数字化型转变
        </div>
        <div class="SaaS-main-logo">
          <img src="../assets/system/SaaS.png" alt="" />
          <div class="SaaS-main-logo-text">
            <div>
              人力资源管理SAAS系统服务于人力资源行业，为人力资源公司提供
            </div>
            <div>
              业务数字化解决方案,通过大数据挖掘、分析、呈现、分发等技术开发，
            </div>
            <div>为人力资源产业的上、下游企业提供数字服务:</div>
            <div>提供企业的信用画像和企业关联图谱;</div>
            <div>建立人力资源公司专属的简历库;</div>
            <div>提供线上电子合同签约服务；</div>
            <div>解决员工智能发薪问题；</div>
            <div>
              为应聘员工提供招聘单位的信息画像，及用工企业画像、工作岗位、生
            </div>
            <div>
              活环境、劳务福利等可视化服务，达到提升招聘效果，稳定招聘留存率
            </div>
            <div>为行业监管提供数字支持</div>
          </div>
        </div>
        <div class="SaaS-main-card">
          <div class="SaaS-main-card-item">
            <div>
              <div class="SaaS-main-card-item-text centered">
                业务流程数字化
              </div>
              <div class="centered">线下门店接入数字化管理，把</div>
              <div class="centered">控员工动态，完善管理体系</div>
            </div>
            <img src="../assets/system/SaaS1.png" alt="" />
            <div class="SaaS-main-card-item-btn">数据管理</div>
          </div>
          <div class="SaaS-main-card-item">
            <div>
              <div class="SaaS-main-card-item-text centered">信息便捷化</div>
              <div class="centered">小程序页面即可时刻了解公司</div>
              <div class="centered">业务动态，随时随地完成每一</div>
              <div>步操作，办公更便捷</div>
            </div>
            <img src="../assets/system/SaaS2.png" alt="" />
            <div class="SaaS-main-card-item-btn">掌上管理</div>
          </div>
          <div class="SaaS-main-card-item">
            <div>
              <div class="SaaS-main-card-item-text centered">
                招聘流程线上化
              </div>
              <div class="centered">信息化时代，越来越多人力资</div>
              <div class="centered">源服务机构开始自营线上网招</div>
              <div>平台，提高招聘能力</div>
            </div>
            <img src="../assets/system/SaaS3.png" alt="" />
            <div class="SaaS-main-card-item-btn">招聘管理</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 产品功能特点 -->
    <div class="characteristic">
      <div class="characteristic-main w">
        <div class="centered">产品功能特点</div>
        <div class="characteristic-main-text centered">
          全流程周期管理，缩短招聘时间
        </div>
        <div class="characteristic-main-card">
          <!-- 第一个 -->
          <!-- 小 -->
          <div
            class="characteristic-main-card-item"
            @mouseover="stateOne = !stateOne"
            @mouseout="stateOne = !stateOne"
            v-show="stateOne"
          >
            <img src="../assets/system/characteristic1.png" alt="" />
            <div>私域化线上招聘</div>
            <div class="interval"></div>
            <div class="characteristic-main-card-item-text">一键获客</div>
            <div class="characteristic-main-card-item-text">自动定位</div>
            <div class="characteristic-main-card-item-text">在线直聊</div>
            <div class="characteristic-main-card-item-text">私域化流量</div>
          </div>
          <!-- 大 -->
          <div
            v-show="!stateOne"
            @mouseover="stateOne = !stateOne"
            @mouseout="stateOne = !stateOne"
            class="characteristic-main-card-itemBig"
          >
            <div class="characteristic-main-card-itemBig-top">
              私域化线上招聘模式
            </div>
            <div class="characteristic-main-card-itemBig-text">
              <div class="text">一键获客</div>
              <div>快速获取求职者手机号，小程序一键授权</div>
            </div>
            <div class="characteristic-main-card-itemBig-text">
              <div class="text">自动定位</div>
              <div>自动获取求职者位置信息，精准邀约求职</div>
            </div>
            <div class="characteristic-main-card-itemBig-text">
              <div class="text">在线直聊</div>
              <div>求职者与招聘专员在线沟通，沟通更顺畅</div>
            </div>
            <div class="characteristic-main-card-itemBig-text">
              <div class="text">私域化流量</div>
              <div>独立后台，打造自己的劳务品牌</div>
            </div>
          </div>

          <!-- 第二个 -->
          <!-- 小 -->
          <div
            class="characteristic-main-card-item"
            @mouseover="stateTwo = !stateTwo"
            @mouseout="stateTwo = !stateTwo"
            v-show="stateTwo"
          >
            <img src="../assets/system/characteristic2.png" alt="" />
            <div>全员代理模式</div>
            <div class="interval"></div>
            <div class="characteristic-main-card-item-text">精细化</div>
            <div class="characteristic-main-card-item-text">易参与</div>
            <div class="characteristic-main-card-item-text">易分享</div>
          </div>
          <!-- 大 -->
          <div
            v-show="!stateTwo"
            @mouseover="stateTwo = !stateTwo"
            @mouseout="stateTwo = !stateTwo"
            class="characteristic-main-card-itemBig"
          >
            <div class="characteristic-main-card-itemBig-top">
              全员代理模式，引流更高效
            </div>
            <div class="characteristic-main-card-itemBig-text">
              <div class="text">精细化</div>
              <div>通过设置不同角色精细化运营带来各渠道流量。</div>
            </div>
            <div class="characteristic-main-card-itemBig-text">
              <div class="text">易参与</div>
              <div>自定义新人红包、推荐红包，让流量获取更简单。</div>
            </div>
            <div class="characteristic-main-card-itemBig-text">
              <div class="text">易分享</div>
              <div>转发链接二维码分享、一键分享职位，自动生成招聘海报。</div>
            </div>
          </div>

          <!-- 第三个 -->
          <!-- 小 -->
          <div
            class="characteristic-main-card-item"
            @mouseover="stateThree = !stateThree"
            @mouseout="stateThree = !stateThree"
            v-show="stateThree"
          >
            <img src="../assets/system/characteristic3.png" alt="" />
            <div>一键发薪</div>
            <div class="interval"></div>
            <div class="characteristic-main-card-item-text">智能核对</div>
            <div class="characteristic-main-card-item-text">一键批量发薪</div>
            <div class="characteristic-main-card-item-text">在线借支</div>
            <div class="characteristic-main-card-item-text">多种模式</div>
          </div>
          <!-- 大 -->
          <div
            v-show="!stateThree"
            @mouseover="stateThree = !stateThree"
            @mouseout="stateThree = !stateThree"
            class="characteristic-main-card-itemBig"
          >
            <div class="characteristic-main-card-itemBig-top">
              私域化线上招聘模式
            </div>
            <div class="characteristic-main-card-itemBig-text margin">
              <div class="text">智能核对</div>
              <div>实名核对员工身份信息，包括员工姓名、身份证号、手机号</div>
            </div>
            <div class="characteristic-main-card-itemBig-text margin">
              <div class="text">一键批量发薪</div>
              <div>缩短打款时间，不限地点不限时间一键发放，无手续费</div>
            </div>
            <div class="characteristic-main-card-itemBig-text margin">
              <div class="text">在线借支</div>
              <div>改变传统借支模式。借支核销一目了然，轻松对账</div>
            </div>
            <div class="characteristic-main-card-itemBig-text margin">
              <div class="text">多种模式</div>
              <div>支持支付宝&银行卡发放。日结、周结、长假工工资都能发</div>
            </div>
          </div>

          <!-- 第四个 -->
          <!-- 小 -->
          <div
            class="characteristic-main-card-item"
            @mouseover="stateFour = !stateFour"
            @mouseout="stateFour = !stateFour"
            v-show="stateFour"
          >
            <img src="../assets/system/characteristic4.png" alt="" />
            <div>全周期管理</div>
            <div class="interval"></div>
            <div class="characteristic-main-card-item-text">多种方式录名单</div>
            <div class="characteristic-main-card-item-text">数据监控</div>
            <div class="characteristic-main-card-item-text">项目管理</div>
            <div class="characteristic-main-card-item-text">周期数据表</div>
          </div>
          <!-- 大 -->
          <div
            v-show="!stateFour"
            @mouseover="stateFour = !stateFour"
            @mouseout="stateFour = !stateFour"
            class="characteristic-main-card-itemBig"
          >
            <div class="characteristic-main-card-itemBig-top">
              全周期管理随时掌控人员动态
            </div>
            <div class="characteristic-main-card-itemBig-text margin">
              <div class="text">多种方式录名单</div>
              <div>支持表格一键导入、员工扫码录入、身份证录入</div>
            </div>
            <div class="characteristic-main-card-itemBig-text margin">
              <div class="text">数据监控</div>
              <div>实时监控平台数据流量，方便二次开发和转化</div>
            </div>
            <div class="characteristic-main-card-itemBig-text margin">
              <div class="text">项目管理</div>
              <div>合作方企业信息实施变更，企业招聘入职率实时展示</div>
            </div>
            <div class="characteristic-main-card-itemBig-text margin">
              <div class="text">周期数据表</div>
              <div>随时通过大数据了解公司经营状况，针对性调整公司战略方向</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 产品优势 -->
    <div class="advantage">
      <div class="advantage-main w">
        <div>产品优势</div>
        <div class="advantage-main-text">移动数据实时监控，方便更快捷</div>
        <div class="advantage-main-card">
          <!-- 个性化定制 -->
          <div class="advantage-main-card-item">
            <div style="margin: 0 auto">
              <img src="../assets/system/advantage1.png" alt="" />
            </div>
            <div class="advantage-main-card-item-text">个性化定制</div>
            <div class="advantage-main-card-item-icon">
              <img class="yes" src="../assets/system/yes.png" alt="" />
              <div>专属招聘页面</div>
            </div>
            <div class="advantage-main-card-item-icon">
              <img class="yes" src="../assets/system/yes.png" alt="" />
              <div>定制化功能窗口</div>
            </div>
            <div class="advantage-main-card-item-icon">
              <img class="yes" src="../assets/system/yes.png" alt="" />
              <div>生成招聘海报</div>
            </div>
            <div class="advantage-main-card-item-img">
              <img
                class="bottomImg"
                src="../assets/system/advantageBg.png"
                alt=""
              />
              <div class="advantage-main-card-item-img-text">
                <div>自定义定制需求</div>
                <div>迅速驰骋招聘蓝海</div>
              </div>
            </div>
          </div>
          <!-- 移动化服务 -->
          <div class="advantage-main-card-item">
            <div style="margin: 0 auto">
              <img src="../assets/system/advantage2.png" alt="" />
            </div>
            <div class="advantage-main-card-item-text">移动化服务</div>
            <div class="advantage-main-card-item-icon">
              <img class="yes" src="../assets/system/yes.png" alt="" />
              <div>快速发布招聘</div>
            </div>
            <div class="advantage-main-card-item-icon">
              <img class="yes" src="../assets/system/yes.png" alt="" />
              <div>一键快速投保</div>
            </div>
            <div class="advantage-main-card-item-icon">
              <img class="yes" src="../assets/system/yes.png" alt="" />
              <div>快速批量发薪</div>
            </div>
            <div class="advantage-main-card-item-img">
              <img
                class="bottomImg"
                src="../assets/system/advantageBg.png"
                alt=""
              />
              <div class="advantage-main-card-item-img-text">
                <div>一部手机随时随地</div>
                <div>完成繁琐招聘流程</div>
              </div>
            </div>
          </div>
          <!-- 数字化管理 -->
          <div class="advantage-main-card-item">
            <div style="margin: 0 auto">
              <img src="../assets/system/advantage3.png" alt="" />
            </div>
            <div class="advantage-main-card-item-text">数字化管理</div>
            <div class="advantage-main-card-item-icon">
              <img class="yes" src="../assets/system/yes.png" alt="" />
              <div>信息流量监控</div>
            </div>
            <div class="advantage-main-card-item-icon">
              <img class="yes" src="../assets/system/yes.png" alt="" />
              <div>企业资金监控</div>
            </div>
            <div class="advantage-main-card-item-icon">
              <img class="yes" src="../assets/system/yes.png" alt="" />
              <div>渠道方数据管理</div>
            </div>
            <div class="advantage-main-card-item-img">
              <img
                class="bottomImg"
                src="../assets/system/advantageBg.png"
                alt=""
              />
              <div class="advantage-main-card-item-img-text">
                <div>信息数据实时监控</div>
                <div>企业数据一目了然</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 公共底部 -->
    <Bottom @btnClick="tryOut" />
  </div>
</template>

<script>
import Bottom from '@/components/Bottom.vue'
import { mapMutations } from 'vuex'
export default {
  name: 'DemoVueSystem',
  components: {
    Bottom
  },
  data() {
    return {
      stateOne: true,
      stateTwo: true,
      stateThree: true,
      stateFour: true
    }
  },
  methods: {
    ...mapMutations(['editIndex']),

    mouseoverState() {
      this.stateOne = !this.stateOne
    },
    mouseoutState() {
      this.stateOne = !this.stateOne
    },

    /** 试用 */
    tryOut() {
      localStorage.setItem('index', 0)
      this.editIndex(0)
      this.$router.push({ path: '/tryOut', query: { state: 1 } })
    }
  }
}
</script>

<style lang="less" scoped>
.w {
  width: 1200px;
  margin: 0 auto;
}

.centered {
  text-align: center;
  // margin-bottom: 20px;
}

.margin {
  margin-top: 10px !important;
}

.system {
  padding-top: 77px;
  .top {
    color: #000;
    height: 520px;
    min-width: 1200px;
    background-color: #b9dafb;

    .top-main {
      display: flex;
      height: 100%;
      justify-content: space-between;

      .top-main-left {
        color: #333;
        font-size: 52px;
        margin-top: 151px;

        .top-main-left-text {
          font-size: 24px;
          color: #333333;
          margin: 30px 0 43px;
        }

        .top-main-left-btn {
          width: 138px;
          height: 46px;
          cursor: default;
          font-size: 20px;
          line-height: 46px;
          color: #438efc;
          text-align: center;
          border: 1px solid #438efc;
          border-radius: 6px;
        }
      }

      .top-main-right {
        img {
          margin-top: 45px;
          width: 576px;
          height: 475px;
        }
      }
    }
  }

  .puzzle {
    min-width: 1200px;
    height: 420px;
    background-color: #fff;

    .puzzle-main {
      height: 100%;
      padding-top: 78px;
      color: #333333;
      font-size: 36px;
      text-align: center;

      .puzzle-main-text {
        font-size: 20px;
        color: #666666;
        margin: 17px 0 41px;
      }

      .puzzle-main-card {
        display: flex;
        color: #333333;
        font-size: 20px;
        justify-content: space-between;

        .puzzle-main-card-item {
          position: relative;

          img {
            width: 288px;
            height: 130px;
          }

          .puzzle-main-card-item-text {
            position: absolute;
            top: 47%;
            left: 59px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }

  .tradition {
    min-width: 1200px;
    height: 662px;
    font-size: 36px;
    color: #333333;
    background-color: #f3f5f9;

    .tradition-main {
      padding-top: 98px;

      .tradition-main-text {
        color: #666666;
        font-size: 20px;
        margin: 18px 0 40px;
      }

      .tradition-main-card {
        display: flex;
        color: #333333;
        font-size: 18px;
        justify-content: space-between;

        .tradition-main-card-item {
          width: 288px;
          height: 350px;
          background: #ffffff;
          box-shadow: 0px 1px 8px 0px rgba(187, 187, 187, 0.4);
          border-radius: 8px;

          img {
            width: 288px;
            height: 202px;
            display: block;
          }

          .tradition-main-card-item-text {
            padding: 30px 21px 0 22px;
          }
        }
      }
    }
  }

  .scheme {
    min-width: 1200px;
    height: 572px;
    font-size: 36px;
    color: #333333;
    background-color: #ffffff;

    .scheme-main {
      padding-top: 97px;
      height: 100%;

      .scheme-main-text {
        color: #666666;
        font-size: 20px;
        margin: 18px 0 54px;
      }

      .scheme-main-card {
        display: flex;
        justify-content: space-between;

        .scheme-main-card-item {
          width: 240px;
          font-size: 18px;
          color: #333333;

          img {
            width: 240px;
            height: 176px;
            display: block;
            margin-bottom: 29px;
          }
        }
      }
    }
  }

  .SaaS {
    min-width: 1200px;
    height: 1187px;
    font-size: 36px;
    color: #333333;
    background-color: #f3f5f9;

    .SaaS-main {
      height: 100%;
      border-top: 1px solid #f3f5f9;
      // background-color: red;

      .SaaS-main-text {
        color: #666666;
        font-size: 20px;
        margin: 18px 0 36px;
      }

      .SaaS-main-logo {
        display: flex;
        padding: 0 18px 0 29px;
        justify-content: space-between;

        img {
          width: 450px;
          height: 380px;
        }

        .SaaS-main-logo-text {
          line-height: 29px;
          margin-top: 61px;
          font-size: 18px;
          color: #333333;
        }
      }

      .SaaS-main-card {
        display: flex;
        margin-top: 69px;
        justify-content: space-between;

        .SaaS-main-card-item {
          display: flex;
          width: 360px;
          height: 430px;
          padding-top: 60px;
          padding-bottom: 30px;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          font-size: 18px;
          color: #333333;
          background: #ffffff;
          border: 1px solid #efefef;
          border-radius: 4px;

          img {
            width: 104px;
            height: 130px;
            // margin: 0 auto 32px;
          }

          .SaaS-main-card-item-btn {
            width: 130px;
            height: 44px;
            line-height: 44px;
            text-align: center;
            background: #ffffff;
            border: 1px solid #efefef;
            box-shadow: 0px 2px 4px 0px rgba(228, 228, 228, 0.2);
            border-radius: 22px;
          }

          .SaaS-main-card-item-text {
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 3px;
          }
        }
      }
    }
  }

  .characteristic {
    min-width: 1200px;
    height: 800px;
    font-size: 36px;
    color: #333333;
    background-color: #fff;
    // background-color: red;

    .characteristic-main {
      height: 100%;
      padding-top: 99px;
      // background-color: pink;

      .characteristic-main-text {
        font-size: 20px;
        color: #666666;
        margin: 16px 0 49px;
      }

      .characteristic-main-card {
        display: flex;
        justify-content: center;

        .characteristic-main-card-item {
          display: flex;
          width: 260px;
          height: 480px;
          font-weight: 700;
          color: #333333;
          font-size: 24px;
          padding-top: 70px;
          align-items: center;
          flex-direction: column;
          background: #ffffff;
          border: 1px solid #e6e7e8;

          img {
            width: 50px;
            height: 50px;
            margin-bottom: 24px;
          }

          .interval {
            width: 95px;
            height: 1px;
            margin: 34px 0 32px;
            border: 1px solid #e6e7e8;
          }

          .characteristic-main-card-item-text {
            font-size: 20px;
            color: #000;
            margin-bottom: 20px;
            font-weight: 400;
          }
        }

        .characteristic-main-card-itemBig {
          width: 420px;
          height: 480px;
          // background: skyblue;
          border: 1px solid #e6e7e8;

          .characteristic-main-card-itemBig-top {
            height: 80px;
            padding-left: 43px;
            line-height: 80px;
            color: #ffffff;
            font-size: 24px;
            background-color: #438efc;
          }

          .characteristic-main-card-itemBig-text {
            margin-top: 34px;
            font-size: 18px;
            padding-left: 41px;
            padding-right: 39px;
            color: #747d87;

            .text {
              font-size: 20px;
              margin-bottom: 10px;
              color: #000000;
            }
          }
        }
      }
    }
  }

  .advantage {
    min-width: 1200px;
    height: 776px;
    font-size: 36px;
    color: #333333;
    background: #f3f5f9;
    background-color: #f3f5f9;

    .advantage-main {
      height: 100%;
      padding-top: 99px;
      text-align: center;
      // background-color: pink;

      .advantage-main-text {
        font-size: 20px;
        color: #666666;
        margin: 15px 0 57px;
      }

      .advantage-main-card {
        display: flex;
        justify-content: space-between;

        .advantage-main-card-item {
          position: relative;
          display: flex;
          width: 360px;
          height: 450px;
          color: #333;
          font-size: 22px;
          padding-top: 40px;
          // align-items: center;
          flex-direction: column;
          background: #ffffff;
          border-radius: 12px;

          img {
            width: 108px;
            height: 92px;
          }

          .advantage-main-card-item-text {
            font-weight: 700;
            font-size: 28px;
            color: #262626;
            margin: 17px 0 20px;
          }

          .yes {
            width: 24px;
            height: 24px;
            margin-right: 10px;
          }

          .advantage-main-card-item-icon {
            display: flex;
            margin-bottom: 21px;
            align-items: center;
            margin-left: 90px;
            // justify-content: center;
          }

          .advantage-main-card-item-img {
            position: absolute;
            width: 360px;
            height: 80px;
            left: 0;
            bottom: 0;

            .advantage-main-card-item-img-text {
              position: absolute;
              top: 15px;
              left: 50%;
              font-size: 20px;
              color: #ffffff;
              text-align: center;
              transform: translateX(-50%);
            }
          }

          .bottomImg {
            width: 360px;
            height: 80px;
          }
        }
      }
    }
  }
}
</style>
