<template>
  <div id="apps">
    <div class="SwiperBox">
      <!-- 图片 -->
      <!-- <img
        :class="['imgCss', ShowImg == index ? 'ShowCss' : '']"
        :src="item.imgUrl"
        v-for="(item, index) in imgList"
        :key="index"
      /> -->
      <div v-for="(item, index) in imgList" :key="index" class="SwiperBox-main">
        <img
          :class="['imgCss', ShowImg == index ? 'ShowCss' : '']"
          :src="item.imgUrl"
        />

        <!-- 第一个banner的文字 -->
        <div
          class="banner-info"
          v-show="imgNum == 1"
          :class="['textCss', ShowImg == index ? 'ShowTextCss' : '']"
        >
          <img
            class="bannerlogo1"
            style="width: 640px; height: 560px"
            src="../assets/banner1logo.png"
            alt=""
            :class="['imgCss', ShowImg == index ? 'ShowCss' : '']"
          />

          <div>人力资源数字科技</div>
          <div>服务平台</div>
          <div class="banner-info-text">简单、高效、智能的人力资源管理平台</div>
          <div class="banner-info-btn" @click="href">立即体验</div>
        </div>
        <!-- 第二个banner的文字 -->
        <div
          class="banner-info"
          v-show="imgNum == 2"
          :class="['textCss', ShowImg == index ? 'ShowTextCss' : '']"
        >
          <div style="color: #333333">
            <div>人力资源数字科技</div>
            <div>服务平台</div>
            <div class="banner-info-text">
              简单、高效、智能的人力资源管理平台
            </div>
            <div
              @click.stop="href"
              class="banner-info-btn"
              style="background: #5498fc; color: #fff"
            >
              立即体验
            </div>
          </div>
        </div>

        <!-- 第三个banner的文字 -->
        <div
          class="banner-info"
          v-show="imgNum == 3"
          :class="['textCss', ShowImg == index ? 'ShowTextCss' : '']"
        >
          <div style="color: #333333">
            <img
              class="bannerlogo1 bannerlogo3"
              style="width: 884px; height: 322px"
              src="../assets/bannerlogo3.png"
              alt=""
              :class="['imgCss', ShowImg == index ? 'ShowCss' : '']"
            />
            <div
              class="banner-info-btn3"
              style="background: #0eadff; color: #fff"
              @click.stop="href"
            >
              立即体验
            </div>
          </div>
        </div>

        <!-- 第四个banner的文字 -->
        <div
          class="banner-info"
          v-show="imgNum == 4"
          :class="['textCss', ShowImg == index ? 'ShowTextCss' : '']"
        >
          <img
            class="bannerlogo1"
            style="width: 640px; height: 560px"
            src="../assets/bannerlogo4.png"
            alt=""
            :class="['imgCss', ShowImg == index ? 'ShowCss' : '']"
          />

          <div>灵活用工SaaS平台</div>
          <div class="banner-info-text">代付到微信、支付宝、银行卡，秒到账</div>
          <div class="banner-info-text">
            为灵活用工服务商业务运营提供系统支持
          </div>
          <div
            class="banner-info-btn"
            style="color: #5e23ea"
            @click.stop="href"
          >
            立即体验
          </div>
        </div>

        <!-- 第五个banner的文字 -->
        <div
          class="banner-info"
          v-show="imgNum == 5"
          :class="['textCss', ShowImg == index ? 'ShowTextCss' : '']"
        >
          <div style="color: #333333">
            <img
              class="bannerlogo1 bannerlogo5"
              style="width: 812px; height: 130px"
              src="../assets/bannerlogo5.png"
              alt=""
              :class="['imgCss', ShowImg == index ? 'ShowCss' : '']"
            />
            <div
              @click="href"
              class="banner-info-btn3 banner-info-btn5"
              style="background: #fff; color: #050f70"
            >
              立即体验
            </div>
          </div>
        </div>

        <div class="slideshow">
          <div class="slideshow-item">
            <img src="../assets/efficient.png" alt="" />
            <div class="generalize">高效</div>
            <div>批量审核一键增减员</div>
          </div>
          <div class="slideshow-item">
            <img src="../assets/flexible.png" alt="" />
            <div class="generalize">灵活</div>
            <div>自定义配置多地参数</div>
          </div>
          <div class="slideshow-item">
            <img src="../assets/stabilization.png" alt="" />
            <div class="generalize">稳定</div>
            <div>自主研发系统全程在线</div>
          </div>
          <div class="slideshow-item">
            <img src="../assets/good.png" alt="" />
            <div class="generalize">好用</div>
            <div>随时随地查询及办理</div>
          </div>

          <div class="instBox">
            <div
              v-for="(item, index) in imgList.length"
              :key="index"
              @click="ShowImg = index"
              :class="['inst', ShowImg == index ? 'instActv' : '']"
            ></div>
          </div>

          <div class="putOnRecords">
            <div class="badge" @click="record">
              <img src="../assets/badge.png" alt="" />
              <div>鄂公网安备 42011102004646号</div>
            </div>
            <div class="record" @click="record">
              Copyright 薪津科技 All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  components: {},
  data() {
    return {
      imgList: [
        {
          id: 1,
          imgUrl: require('../assets/banner1.png')
        },
        {
          id: 2,
          imgUrl: require('../assets/banner2.png')
        },
        {
          id: 3,
          imgUrl: require('../assets/banner3.png')
        },
        {
          id: 4,
          imgUrl: require('../assets/banner4.png')
        },
        {
          id: 5,
          imgUrl: require('../assets/banner5.png')
        }
      ],
      ShowImg: 0, // 表示当前显示的图片
      flag: true, // 用来节流防止重复点击
      start: null, // 自动执行下一张定时器
      imgNum: 1
    }
  },

  created() {},

  mounted() {
    this.setTimeoFun()
  },
  methods: {
    ...mapMutations(['editIndex']),

    // 这里定义一个鼠标移入移出事件，鼠标悬停时停止自动轮播，鼠标移出则重新执行自动轮播
    MouseFun(type) {
      // 停止定时器            // 重新执行定时器
      type == '移入' ? clearTimeout(this.start) : this.setTimeoFun()
    },
    setTimeoFun() {
      this.start = setInterval(() => {
        this.NextFun()
      }, 3000)
    },
    // 这里通过额外封装的节流函数触发 PrevFun() 和 NextFun(),以达到防止重复点击的效果
    throttle(fun) {
      if (this.flag) {
        this.flag = false
        fun() // 此为模板中传递进来的PrevFun()或NextFun()函数
        setTimeout(() => {
          this.flag = true
        }, 1200) // 节流间隔时间
      }
    },
    // 上一张
    PrevFun() {
      if (this.ShowImg !== 0) {
        this.ShowImg--
      } else {
        this.ShowImg = this.imgList.length - 1
      }
    },
    // 下一张
    NextFun() {
      if (this.ShowImg !== this.imgList.length - 1) {
        this.ShowImg++
      } else {
        this.ShowImg = 0
      }
      this.imgNum = this.ShowImg - 0 + 1
      // console.log('<<<<<<', this.imgNum)
    },

    // 体验
    /** 试用 */
    href() {
      window.location.href = 'https://api.027xjkj.com/login'
    },

    /** 备案号 */
    record() {
      window.location.href =
        'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42011102004646'
    }
    // tryOut() {
    //   localStorage.setItem('index', 0)
    //   this.editIndex(0)
    //   this.$router.push({ path: '/tryOut', query: { state: 1 } })
    // }
  }
}
</script>

<style lang="less" scoped>
body {
  min-width: 1200px;
}

.main-banner {
  margin-bottom: 100px;
}

.banner {
  min-width: 1200px;
  position: relative;
  // background-image: url('../assets/banner1.png');
  // min-height: 1034px;
  // background-size: cover;
  // background-repeat: no-repeat;
}
/* 图片容器样式 */
.SwiperBox {
  position: relative;
  width: 100%;
  border: 1px solid #ccc;
  box-sizing: border-box;
  cursor: pointer;

  // position: fixed;
  // left: 0;
  // top: 0;
  // width: 100%;
  // box-sizing: border-box;
}

.SwiperBox-main {
  position: relative;

  .banner-info {
    position: absolute;
    font-size: 60px;
    color: #fff;
    left: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 120%);
    width: 1200px;
    margin: 0 auto;

    .bannerlogo1 {
      position: absolute;
      top: 62%;
      left: 74%;
      transform: translate(-50%, -50%);
    }

    .bannerlogo3 {
      left: 50%;
      transform: translate(-50%, 100%);
    }

    .bannerlogo5 {
      left: 50%;
      transform: translate(-50%, 280%);
    }

    .banner-info-text {
      font-size: 24px;
      margin: 24px 0 46px;
    }

    .banner-info-btn {
      display: flex;
      width: 160px;
      height: 44px;
      color: #1851fe;
      font-size: 22px;
      align-items: center;
      background: #ffffff;
      border-radius: 22px;
      justify-content: center;
      z-index: 999;
    }

    .banner-info-btn3 {
      position: absolute;
      top: 730px;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      width: 160px;
      height: 44px;
      color: #1851fe;
      font-size: 22px;
      align-items: center;
      background: #ffffff;
      border-radius: 22px;
      justify-content: center;
      z-index: 999;
    }

    .banner-info-btn5 {
      top: 615px;
    }
  }
}

/* 图片默认样式 */
.imgCss {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  opacity: 0;
  transition: 0.8s; /* 淡入淡出过渡时间 */
}

.textCss {
  // position: absolute;
  // left: 0px;
  // top: 0px;
  width: 100%;
  opacity: 0;
  transition: 0.8s; /* 淡入淡出过渡时间 */
}

/* 图片选中样式(继承上方默认样式) */
.ShowCss {
  opacity: 1;
}

.ShowTextCss {
  opacity: 1;
}

/* 两个按钮共有的样式,也可直接使用箭头图片替代 */
.leftBtn,
.rightBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(109, 109, 109, 0.445);
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
}
.leftBtn {
  left: 10px;
}
.rightBtn {
  right: 10px;
}
/* 下方指示器盒子 */
.instBox {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -25px;
  display: flex;
}
/* 小圆点 */
.inst {
  width: 10px;
  height: 10px;
  border: 1px solid #ccc;
  margin-right: 8px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;

  width: 40px;
  height: 6px;
  background: #b5b5b5;
  opacity: 0.5;
  border-radius: 3px;
}
.inst:last-child {
  margin-right: 0px;
}
.instActv {
  border: 1px solid #5498fc;
  background: #5498fc;
}
#apps {
  // width: 100%;
  min-width: 1200px;
  // padding: 120px;
  display: flex;
  justify-content: center;
}

.slideshow {
  position: absolute;
  display: flex;
  // bottom: -88px;
  font-family: Microsoft YaHei;
  bottom: -1056px;
  left: 50%;
  width: 1200px;
  height: 210px;
  background: #ffffff;
  box-shadow: 0px 4px 14px 0px rgba(118, 118, 118, 0.2);
  border-radius: 12px;
  transform: translateX(-50%);

  .slideshow-item {
    flex: 1;
    font-size: 18px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100px;
      height: 100px;
    }

    .generalize {
      font-size: 20px;
      font-weight: 700;
      color: #333333;
    }
  }

  .putOnRecords {
    position: absolute;
    left: 50%;
    height: 50px;
    bottom: -86px;
    color: #939393;
    font-size: 14px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .badge {
      display: flex;
      margin-right: 29px;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
