<template>
  <div>
    <div class="tryOut">
      <img src="../assets/system/tryOut.png" alt="" />
      <div class="tryOut-text">7*24小时服务 立即预约免费试用</div>
      <div class="tryOut-btn" @click="btn">免费试用</div>
    </div>
    <div class="bottoms">
      <div class="bottoms-main w">
        <div class="left">
          <img src="../assets/system/logo.png" class="logo" alt="" />
          <div class="site">
            <img src="../assets/system/place.png" alt="" />
            <div>湖北省武汉市洪山区野芷湖西路16号创意天地4号楼311室</div>
          </div>
          <div class="relation">
            <div class="relation-phone">
              <img src="../assets/system/phone.png" alt="" />
              <div>400-027-8060</div>
            </div>
            <div class="relation-note">
              <img src="../assets/system/note.png" alt="" />
              <div>zhangfan@027xjkj.com</div>
            </div>
          </div>
          <div class="badge" @click="record">
            <img src="../assets/system/badge.png" alt="" />
            <div>鄂公网安备 42011102004646号</div>
          </div>
          <div class="text" @click="record">
            Copyright 薪津科技 All Rights Reserved
          </div>
        </div>
        <div class="rigth">
          <div class="rigth-main">
            <img class="qr" src="../assets/system/qr.png" alt="" />
            <div class="rigth-main-text">
              <div>关注薪津科技</div>
              <div>微信扫一扫</div>
              <div>官方小程序</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OfficialWebsiteBottom',

  data() {
    return {}
  },

  mounted() {},

  methods: {
    btn() {
      this.$emit('btnClick')
    },

    /** 备案号 */
    record() {
      window.location.href =
        'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=42011102004646'
    }
  }
}
</script>

<style lang="less" scoped>
.w {
  width: 1200px;
  margin: 0 auto;
}

.tryOut {
  position: relative;
  min-width: 1200px;
  height: 280px;

  img {
    width: 100%;
    height: 100%;
  }

  .tryOut-text {
    position: absolute;
    top: 92px;
    left: 50%;
    font-size: 30px;
    color: #ffffff;
    transform: translateX(-50%);
  }

  .tryOut-btn {
    position: absolute;
    display: flex;
    cursor: default;
    top: 160px;
    left: 50%;
    width: 150px;
    height: 44px;
    background: #ffffff;
    border-radius: 22px;
    color: #438efc;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    transform: translateX(-50%);
  }
}

.bottoms {
  height: 340px;
  min-width: 1200px;
  background-color: #071724;

  .bottoms-main {
    height: 100%;
    display: flex;
    justify-content: space-between;
    // background-color: skyblue;

    .left {
      color: #d4d4d4;
      font-size: 15px;
      .logo {
        width: 170px;
        height: 60px;
        margin: 50px 0 52px;
      }

      .site {
        display: flex;
        align-items: center;

        img {
          width: 18px;
          height: 18px;
          margin-right: 7px;
        }
      }

      .relation {
        display: flex;
        margin: 16px 0 18px;

        .relation-phone {
          display: flex;
          align-items: center;

          margin-right: 34px;

          img {
            width: 18px;
            height: 18px;
            margin-right: 7px;
          }
        }

        .relation-note {
          display: flex;
          align-items: center;

          img {
            width: 18px;
            height: 18px;
            margin-right: 12px;
          }
        }
      }

      .badge {
        cursor: pointer;
        display: flex;
        align-items: center;

        img {
          width: 18px;
          height: 18px;
          margin-right: 7px;
        }
      }

      .text {
        margin-top: 16px;
        padding-left: 25px;
        cursor: pointer;
      }
    }

    .rigth {
      .rigth-main {
        display: flex;
        color: #fff;
        font-size: 16px;
        margin-top: 146px;

        .rigth-main-text {
          display: flex;
          margin-left: 13px;
          flex-direction: column-reverse;
        }
      }
      .qr {
        width: 110px;
        height: 110px;
      }
    }
  }
}
</style>
