<template>
  <div class="platform">
    <!-- banner -->
    <div class="platform-banner">
      <div class="platform-banner-main w">
        <div class="platform-banner-main-left">
          <div>灵活用工平台</div>
          <div class="platform-banner-main-left-text">
            互联网+新业态+新服务 一站式全流程服务平台
          </div>
          <div class="platform-banner-main-left-btn" @click="tryOut">
            免费试用
          </div>
        </div>
        <div class="platform-banner-main-rigth">
          <img src="../assets/platform/bannerLogo.png" alt="" />
        </div>
      </div>
    </div>

    <!--业务场景分布  -->
    <div class="scene">
      <div class="scene-main w">
        <div class="centered">业务场景分布</div>
        <div class="scene-main-text centered">基于为企业提供平台化用工难题</div>
        <div class="scene-main-icon">
          <div class="scene-main-icon-item">
            <img src="../assets/platform/scene1.png" alt="" />
            <div>外卖行业</div>
          </div>
          <div class="scene-main-icon-item">
            <img src="../assets/platform/scene2.png" alt="" />
            <div>物流行业</div>
          </div>
          <div class="scene-main-icon-item">
            <img src="../assets/platform/scene3.png" alt="" />
            <div>家政行业</div>
          </div>
          <div class="scene-main-icon-item">
            <img src="../assets/platform/scene4.png" alt="" />
            <div>工厂行业</div>
          </div>
          <div class="scene-main-icon-item">
            <img src="../assets/platform/scene5.png" alt="" />
            <div>服务行业</div>
          </div>
          <div class="scene-main-icon-item">
            <img src="../assets/platform/scene6.png" alt="" />
            <div>销售行业</div>
          </div>
          <div class="scene-main-icon-item">
            <img src="../assets/platform/scene7.png" alt="" />
            <div>零售行业</div>
          </div>
        </div>
        <img class="image-text" src="../assets/platform/scene8.png" alt="" />
      </div>
    </div>

    <!-- 灵活用工SaaS系统6大核心功能 -->
    <div class="kernel">
      <div class="kernel-main w">
        <div class="centered">灵活用工SaaS系统6大核心功能</div>
        <div class="kernel-main-card">
          <div class="kernel-main-card-item item1">
            <img src="../assets/platform/kernel1.png" alt="" />
            <div class="kernel-main-card-item-text">企业一分钟发布任务</div>
            <div>操作流程简单轻松，PC即可操作，既方便又快捷</div>
          </div>
          <div class="kernel-main-card-item">
            <img src="../assets/platform/kernel2.png" alt="" />
            <div class="kernel-main-card-item-text">个人一站式承接任务</div>
            <div>个人可直接在手机端操作、一键领取任务、签订合作承揽协议</div>
          </div>
          <div class="kernel-main-card-item">
            <img src="../assets/platform/kernel3.png" alt="" />
            <div class="kernel-main-card-item-text">报酬结算万笔秒发</div>
            <div>
              系统支持下发到银行、支付宝、微信，T+0到账，资金安全解决方案
            </div>
          </div>
          <div class="kernel-main-card-item">
            <img src="../assets/platform/kernel4.png" alt="" />
            <div class="kernel-main-card-item-text">发票在线申请</div>
            <div>开票数据自动核对，发票处理状况实时同步</div>
          </div>
          <div class="kernel-main-card-item">
            <img src="../assets/platform/kernel5.png" alt="" />
            <div class="kernel-main-card-item-text">项目数据交付</div>
            <div>个人服务数据交付，企业验收确认</div>
          </div>
          <div class="kernel-main-card-item">
            <img src="../assets/platform/kernel6.png" alt="" />
            <div class="kernel-main-card-item-text">系统大数据风控</div>
            <div>项目数据异常预警，后台人工复检</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 灵活用工SaaS系统 -->
    <div class="SaaSSystem">
      <div class="SaaSSystem-main w">
        <div class="centered">灵活用工SaaS系统</div>
        <div class="SaaSSystem-main-card">
          <div class="SaaSSystem-main-card-item">
            <img src="../assets/platform/SaaSSystem1.png" alt="" />
            <div class="SaaSSystem-main-card-item-text">
              <div class="SaaSSystem-main-card-item-text-title">自主研发</div>
              <div>
                拥有专业领域技术人才，强大的技术团队研发服务能力；拥有多项核心知识产权和软件著作版权
              </div>
            </div>
          </div>
          <div class="SaaSSystem-main-card-item">
            <img src="../assets/platform/SaaSSystem2.png" alt="" />
            <div class="SaaSSystem-main-card-item-text">
              <div class="SaaSSystem-main-card-item-text-title">匠心定制</div>
              <div>
                根据灵活用工的实际应用场景，以及人力资源行业的需求量身打造；技术与功能及时更新迭代，可为用户提供个性化功能定制
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 业务服务流程 -->
    <div class="flow">
      <img src="../assets/platform/flow.png" class="flow-img" alt="" />
      <div class="flow-text centered">
        <div>业务服务流程</div>
        <div class="flow-text-div">基于为企业提供平台化用工难题</div>
        <img src="../assets/platform/flowText.png" alt="" />
      </div>
    </div>

    <!-- 什么是委托代征？ -->
    <div class="entrust">
      <div class="entrust-main w">
        <div class="centered">什么是委托代征？</div>
        <div class="entrust-main-card">
          <img src="../assets/platform/entrust.png" alt="" />
          <div class="entrust-main-card-text">
            委托代征是指税务机关根据《中华人民共和国税收征收管理法则实施细则》有利于税收空管和方便纳税的要求，按照双方自愿、简便征收、强化管理、依法委托的原则和国家有关规定，委托有关单位和人员代征零星分散和异地缴纳的税收的行为
          </div>
        </div>
      </div>
    </div>

    <!-- 公共底部 -->
    <Bottom @btnClick="tryOut" />
  </div>
</template>

<script>
import Bottom from '@/components/Bottom.vue'
import { mapMutations } from 'vuex'
export default {
  name: 'DemoVuePlatform',
  components: {
    Bottom
  },
  methods: {
    ...mapMutations(['editIndex']),

    /** 试用 */
    tryOut() {
      localStorage.setItem('index', 0)
      this.editIndex(0)
      this.$router.push({ path: '/tryOut', query: { state: 2 } })
    }
  }
}
</script>

<style lang="less" scoped>
.centered {
  text-align: center;
}

.platform {
  min-width: 1200px;
  padding-top: 77px;

  .platform-banner {
    height: 520px;
    background-color: #0077e8;

    .platform-banner-main {
      display: flex;
      height: 100%;
      justify-content: space-between;
      // background-color: pink;

      .platform-banner-main-left {
        font-size: 52px;
        color: #ffffff;
        margin-top: 161px;

        .platform-banner-main-left-text {
          font-size: 24px;
          margin: 31px 0 43px;
        }

        .platform-banner-main-left-btn {
          display: flex;
          width: 138px;
          height: 46px;
          font-size: 20px;
          cursor: default;
          justify-content: center;
          align-items: center;
          border: 1px solid #ffffff;
          border-radius: 6px;
        }
      }

      .platform-banner-main-rigth {
        margin-top: 69px;

        img {
          width: 514px;
          height: 450px;
        }
      }
    }
  }

  .scene {
    height: 950px;
    min-width: 1200px;
    background-color: #fff;

    .scene-main {
      height: 100%;
      color: #333333;
      font-size: 36px;
      padding-top: 80px;
      // background-color: pink;

      .scene-main-text {
        color: #666666;
        font-size: 20px;
        margin: 15px 0 39px;
      }

      .scene-main-icon {
        width: 100%;
        height: 130px;
        margin-bottom: 90px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        background: linear-gradient(
          0deg,
          #fefeff 0%,
          #fefeff 43%,
          #ecf4f9 100%
        );
        box-shadow: 0px 4px 5px 1px rgba(142, 168, 226, 0.16);
        border-radius: 8px;

        .scene-main-icon-item {
          width: 73px;
          font-size: 16px;
          text-align: center;
          color: #333333;
          img {
            width: 73px;
            height: 74px;
            display: block;
          }
        }
      }

      .image-text {
        width: 100%;
        height: 440px;
      }
    }
  }

  .kernel {
    height: 717px;
    min-width: 1200px;
    background-color: #f3f5f9;

    .kernel-main {
      height: 100%;
      font-size: 36px;
      color: #333333;
      padding-top: 101px;
      // background-color: pink;

      .kernel-main-card {
        display: flex;
        height: 426px;
        flex-wrap: wrap;
        margin-top: 55px;
        justify-content: space-between;

        .kernel-main-card-item {
          width: 388px;
          height: 200px;
          color: #333333;
          font-size: 18px;
          padding: 36px 59px 0 19px;
          background: #ffffff;
          border: 1px solid #efefef;

          img {
            width: 40px;
            height: 40px;
          }

          .kernel-main-card-item-text {
            font-size: 22px;
            font-weight: 700;
            color: #333333;
            margin: 5px 0 10px;
          }
        }
      }
    }
  }

  .SaaSSystem {
    height: 515px;
    min-width: 1200px;
    background-color: #ffffff;
    .SaaSSystem-main {
      height: 100%;
      font-size: 36px;
      color: #333333;
      padding-top: 100px;
      // background-color: pink;

      .SaaSSystem-main-card {
        margin-top: 50px;
        display: flex;
        height: 320px;
        // background-color: skyblue;
        justify-content: space-between;

        .SaaSSystem-main-card-item {
          position: relative;
          img {
            width: 580px;
            height: 230px;
          }

          .SaaSSystem-main-card-item-text {
            position: absolute;
            top: 50px;
            left: 31px;
            font-size: 18px;
            color: #333333;
            width: 359px;

            .SaaSSystem-main-card-item-text-title {
              color: #333333;
              font-size: 22px;
              font-weight: 700;
              margin-bottom: 22px;
            }
          }
        }
      }
    }
  }

  .flow {
    position: relative;
    height: 830px;
    min-width: 1200px;

    .flow-img {
      height: 830px;
      width: 100%;
    }

    .flow-text {
      position: absolute;
      left: 50%;
      top: 100px;
      font-size: 36px;
      color: #ffffff;
      transform: translateX(-50%);

      .flow-text-div {
        font-size: 20px;
        margin: 14px 0 80px;
      }

      img {
        width: 1205px;
        height: 477px;
      }
    }
  }

  .entrust {
    height: 786px;
    min-width: 1200px;

    .entrust-main {
      height: 100%;
      color: #333333;
      font-size: 36px;
      padding-top: 100px;

      .entrust-main-card {
        display: flex;
        margin-top: 72px;
        justify-content: space-between;

        img {
          width: 599px;
          height: 490px;
        }

        .entrust-main-card-text {
          width: 522px;
          font-size: 24px;
          color: #333333;
          margin-top: 71px;
          line-height: 48px;
        }
      }
    }
  }
}
</style>
